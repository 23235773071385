import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from 'src/components/layouts/default';
import * as classes from './enroll.module.scss';
import StructuredText from 'src/components/base/structuredText';
import GoogleButton from 'src/assets/googleplay.png';
import AppleButton from 'src/assets/appstore.png';
import PhoneNoClip from 'src/assets/phone-no-clip.avif';
import { getAppStoreLink } from 'src/util';

import { useGlobalState } from 'src/components/base/globalState';

function downloadApp(evt, id) {
  window.location = getAppStoreLink(id);
  evt.preventDefault();
}

function downloadIosApp(evt) {
  downloadApp(evt, '#appstore_ios');
}

function downloadAndroidApp(evt) {
  downloadApp(evt, '#appstore_android');
}

export const query = graphql`
  query {
    datoCmsStart {
      enrollTitle
      enrollLede {
        blocks
        links
        value
      }
    }
  }
`;

const EnrollPage = ({ data, location }) => {

  const { state, paymentType, patientReferrer, } = useGlobalState();

  useEffect(() => {
    if (!patientReferrer && (!state || !paymentType)) {
      navigate('/eligibility', { state: { prev: location.pathname, prevLabel: 'Enroll' }});
    }
  }, [patientReferrer, state, paymentType, location]);
  return (
  <Layout location={location} pageTitle={data.datoCmsStart.enrollTitle}>
    <div className={classes.ledeContainer}>
      <div className={classes.lede}>
        <img src={PhoneNoClip} className={classes.ledeImage} />
        <div>
          <StructuredText data={data.datoCmsStart.enrollLede} />
          <div className={classes.download}>
            <a href="#" onClick={downloadIosApp}>
              <img src={AppleButton} />
            </a>
            <a href="#" onClick={downloadAndroidApp}>
              <img src={GoogleButton} />
            </a>
          </div>
        </div>
      </div>
    </div>
    
  </Layout>
);
  }

export default EnrollPage;
